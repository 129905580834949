<template>
<div>
  <b-row class="profile-icons-svg">
    <b-col lg="6">
      <div class="iq-card ">
        <div class="iq-card-body pb-1">
          <div class="d-flex align-items-center justify-content-between">
            <p class="font-size-26 p-0 m-0">{{ $t('marketplace.products') }}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
              <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" fill="#646464"  />
            </svg>
          </div>
          <p class="font-size-30 text-end m-0 pt-4">{{ products.length }}</p>
        </div>
      </div>
    </b-col>
  </b-row>
  <div v-if="loadingData" class="d-flex justify-content-center">
    <spinner-loading text="Loading"/>
  </div>
  <div v-else>
  <b-row v-if="products.length > 0">
    <b-col md="4" v-for="(product,key) in products" :key="key">
      <product-card
      :productItem ="product"
      :inFav="true"
      @deleteFromFav = "deleteFromFav"
      />
    </b-col>
  </b-row>
    <b-row v-else class="d-flex justify-content-center align-items-center  flex-column mt-5">
      <empty-cart class="mb-4"></empty-cart>
      <p class="text-black font-size-22">{{ $t('userProfile.emptyFavourite') }}</p>
      <b-button @click="$router.push('/')" class="w-25" variant="primary">{{ $t('userProfile.goHomepage') }}</b-button>
    </b-row>
  </div>
</div>
</template>

<script>
import ProductCard from '@/modules/servicePages/marketplace/components/productCard'
import marketPlace from '@/modules/servicePages/marketplace/services/marketplace'
import emptyCart from '@/modules/servicePages/marketplace/components/cart/emptyCart'
export default {
  name: 'favourite',
  components: { ProductCard, emptyCart },
  // computed: {
  //   getProductFavourite () {
  //     return this.$store.getters['cart/getProductFavourite']
  //   }
  // },
  data () {
    return {
      loadingData: false,
      products: []
    }
  },
  methods: {
    // addInPayment (id) {
    //   if (this.checkIfLogin()) {
    //
    //     this.$router.push({ name: 'payment-actions', params: { id: id } })
    //   } else {
    //     this.$router.push({ name: 'login' })
    //   }
    // },
    getProductsWishList () {
      this.loadingData = true
      marketPlace.getProductWishlist().then(res => {
        this.products = res.data.data
        this.$store.dispatch('cart/setFavourite', res.data.data)
      }).finally(this.loadingData = false)
    }
  },
  //   deleteFromFav (id) {
  //     const findIndex = this.products.findIndex(product => product.id === id)
  //     this.products.splice(findIndex, 1)
  //   }
  // },
  created () {
    this.getProductsWishList()
  }
}
</script>

<style>

</style>
